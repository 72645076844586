.saveButton{
  margin-top: 2rem;
  margin-bottom: 10rem;
}

.scrollContainer {
  height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 0px;

  // Optional: for better scrolling on webkit browsers
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}


.headerRow {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.mobileRow {
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.5rem;
    border-bottom: 1px solid #eee;

    .datePair {
      display: flex;
      gap: 0.5rem;
      width: 100%;

      // Make date items smaller on mobile
      > div {
        flex: 1;
        min-width: auto !important;
        font-size: 0.9rem;
      }
    }

    // Adjust other items for mobile
    > div {
      width: 100% !important;
      min-width: 100% !important;
      padding: 0.2rem 0;
    }
  }
}