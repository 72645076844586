@import './fonts.scss';
@import '~leaflet/dist/leaflet.css';

:root {
  --max-content-width: 110rem; // 1600px
  --content-padding: 0rem;

  //--primary-color: #B82025;
  --primary-color: dodgerblue;
  --primary-button: dodgerblue;
  --primary-button-color: dodgerblue;
  --primary-button-hover: #0b5fb6;

  --primary-button-color-light: #57a8ff;


  --primary-color-rgb: 184, 32, 37;
  --primary-color-light: #D74C51;
  --primary-color-super-light: #f8eaea;
  --primary-color-dark: #82171b;
  --primary-color-judo: #b82025;
  --primary-color-dark-rgb: 130, 23, 27;

  --color-success: #00c468;
  --color-success-light: #7de7af;
  --color-warning: #ffd47f;
  --color-warning-darker: #feb502;
  --color-danger: #ff7f7f;
  --color-info: #8ec8ff;

  --color-green: #29a929;
  --color-darkgreen: green;

  --text-bg: white;
  --text-fg: black;

  --gray-super-light: #e4e2e2;
  --gray-light: #d9dcde;
  --gray: #888888;
  --gray-dark: #666666;

  --kyu: #56a7ee;
  --kyu-rgb: 13,118,178;

  --dodgerblue: dodgerblue;
  --dodgerblue-rgb: 30,144,255;


  --primary-background: #FAE8E9;
  --primary-form: rgba(246, 133, 159, 0.15);

  --tint: rgba(255, 255, 255, 0.63);

  --top-inset: env(safe-area-inset-top);
  --bottom-inset: env(safe-area-inset-bottom);

  --content-height: calc(100vh - calc(10rem + calc(var(--top-inset) + var(--bottom-inset))));
  --frame-height: calc(5rem );



  --border-radius: 0.25rem;
  --spacing: 0.5rem;

  --font-family: "Montserrat", sans-serif;

  --shadow: 0 0 0.3rem 0.1rem rgba(0,0,0,0.25);

  background: url('images/background.svg') no-repeat;
  background-size: cover;

  //--background: url('images/background.jpg') no-repeat center;
  // --background-desktop: url('images/background.jpg');
  --logo: url("./images/logo.svg");
  --logo-white: url('./images/logo_white.svg');

  --warning-color: #ff874b;
  --error-color: red;
  --green-color: #00aa00;
}

body {
  font-family: var(--font-family);
}

.logo-header {
  background: url('./images/logo_white.svg') no-repeat center;
  background-size: contain;
  height: 4rem;
  width: 6rem;
  position: absolute;
  right: 0;
  top: calc(0.5rem + var(--top-inset));
}

.logo {
  background: url("./images/logo.svg") no-repeat center;
  background-size: contain;
  height: 4rem;
}

.react-transform-wrapper {
  flex: 1;
  height: calc(var(--content-height) - 5rem) !important;
  width: 100vw !important;
}

#checkout {
  width: 100vw;
}

button {
  box-sizing: border-box;
  background-color: var(--primary-button-color);
  //border: 2px solid transparent !important;
  //border:2px solid white !important;
  &:hover {
    background-color: var(--primary-button-hover);
    transition: background-color 0.5s;
    color: white;
    transition: border-color 0.3s;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.greenButton {
  margin: 2rem auto;
  border-radius: 0.5rem;
  display: flex;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  max-width: 99%;
  background-color: var(--primary-button);

  &:hover {
    background-color: var(--primary-button-hover);
    border: 0px !important;
  }
}