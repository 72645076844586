.inputContainer {
  position: relative;
  width: 100%;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  font-size: 1rem;

  width: calc(100% - calc(3 * var(--spacing)));
  margin: calc(0.25 * var(--spacing));
  padding: var(--spacing);
  border-radius: var(--border-radius);
  border: 1px solid var(--gray);
  background-color: var(--primary-color-super-light);
  transition: linear 150ms;

  &:active, &:focus {
    outline: none;
    border-color: var(--primary-color);
    background-color: white;
  }
  &:disabled {
    color: var(--gray);
    background-color: var(--gray-super-light);
  }

  &.withClearButton {
    padding-right: calc(var(--spacing) * 2.5);
  }
}

.label {
  font-size: 0.8rem;
  margin-left: calc(0.5 * var(--spacing));
}

.clearButton {
  position: absolute;
  right: calc(var(--spacing) * 0.75);
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray);
  padding: 4px;
  border-radius: 50%;
  z-index: 2;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: var(--gray-dark, #333);
  }

  &:focus {
    outline: none;
  }
}