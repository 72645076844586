.matchHeader {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
}

.headerTeams {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.headerTeam {
  font-size: 2rem;
  font-weight: bold;
  flex: 1;
  text-align: center;
}

.headerScore {
  font-size: 2rem;
  font-weight: bold;
  margin: 0 1rem;
  text-align: center;
}

.matchDetails {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.matchDetail {
  margin-right: 1.5rem;

  strong {
    margin-right: 0.5rem;
  }
}

.roundTable {
  margin-bottom: 2rem;
  overflow-x: auto;
}

.tabelContainer {
  margin:0.2rem;
  //border: 1px solid lime;
}

.matchTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
  background-color: white;

  th, td {
    padding: 0.2rem;
    border: 1px solid ;
    text-align: center;
    min-width: 2.2rem;
  }

  th {
    background-color: var(--primary-color-judo);
    font-weight: bold;
    color: white;
    border:1px solid white;
    &:nth-child(7), &:nth-child(15) {
      background-color: var(--primary-color);
    }
  }
  td{
    border: 1px solid #999;
  }

  th:first-child {
    border-left: 1px solid var(---primary-color-judo);
  }
  th:last-child {
    border-right: 1px solid var(---primary-color-judo);
  }
}

.teamHeader {
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-left: 1px solid var(--gray-light) !important;
}

.matchRow {
  &:nth-child(even) {
    background-color: #e8e8e8;
  }
}

.winner {
  background-color: #7bde91;
}

// Column width controls
.colNo {
  width: 2rem;
}

.colKG {
  width: 2rem;
  border-right: 1px solid var(--gray-light) !important; /* Add thicker border to create visual separation */
}

.colName {
  width: 180px;
  text-align: left !important;
  font-weight: normal;
  border:1px solid var(--gray-light);
}

.colScore {
  width: 50px;
}

/* Add thicker border to create visual separation between home and away teams */
th:nth-child(4) {
  //border-right: 5px solid var(--gray-light) !important;
}

th:nth-child(10) {
  //border-right: 5px solid var(--gray-light) !important;
}
.scoreDropdown {
  width: 100%;
  padding: 0.1rem;
  border-radius: 0.2rem;
  border: 1px solid #ccc;
}

.fighterDropdown {
  width: 100%;
  padding: 0.1rem;
  border-radius: 0.2rem;
  border: 1px solid #ccc;
  text-align: left;
}


// Sieg field styling
.siegWin {
  background-color: #5aff81;
  color: black;
  font-weight: bold;
  padding: 1px 10px;
}

.siegLoss {
  //background-color: #f8d7da;
  color: black;
  font-weight: bold;
}

// Override for dropdown when editing
select.siegWin {
  padding: 1px 1px;
  background-color: #7bde91;
  color: black;
  border-color: #428a53;

  &:focus {
    border-color: #c3e6cb;
    color: black;
    //box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
}

select.siegLoss {
  padding: 1px 1px;
  background-color: #f8d7da;
  color: black;
  border-color: #f5c6cb;

  &:focus {
    border-color: #f5c6cb;
    color: black;
  //box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}

.summaryRow {
  background-color: #f0f0f0 !important;
  font-weight: bold;

  td {
   // border-top: 2px solid var(--primary-color-judo) !important;
  }
}

.summaryValue {
  font-size: 1.1rem;
  color: black;
}

.summaryShido {
  text-align: center;
  font-size: 1.1em;
  background-color: #ffe6e6; // Light red background for shido
  color: #d9534f; // Red text color for shido
  font-weight: bold;
}

.decisionMatchRow {
  background-color: #f0f0f0;

  td {
    padding: 0.75rem 0.5rem;
  }
}

.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  text-align: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 3px;
    border: 1px solid #ccc;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  input:disabled ~ .checkmark {
    background-color: white;
    cursor: not-allowed;
  }
}

.finalScoreRow {
  background-color: #e8f4f8 !important;

  td {
    border-top: 2px solid var(--primary-color-judo);
    border-bottom: 2px solid var(--primary-color-judo);
  }
}

.finalValue {
  font-size: 1.2rem;
  font-weight: bold;
  color: #dc3545;
}

.summaryRow {
  background-color: #f0f0f0 !important;
  font-weight: bold;

  td {
    border-top: 4px solid var(--primary-color-judo);
  }
}

.summaryValue {
  font-size: 1.1rem;
  color: var(--primary-color);
}

.refereeSelectionContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  margin-left: 0.2rem;
  width: 100%;
}

.refereeSelect {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 24rem;
  max-width: 24rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  label {
    margin-bottom: 0.5rem;
    font-weight: normal;
  }

  .refereeDropdown {
    width: 100%;

    &:global(.is-disabled) {
      opacity: 0.6;
    }
  }
}

.actionButtons {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-top: 1rem;
  width: 100%;
  clear: both;
}

.actionButton {
  transition: background-color 0.2s;
  max-height: 2rem;
}

.editButton {
  &:hover {
  }
}

.saveButton {
  background-color: #28a745;
  color: white;

  &:hover {
    background-color: #218838;
  }
}

.cancelButton {
  background-color: #6c757d;
  color: white;

  &:hover {
    background-color: #5a6268;
  }
}

.resultButton {
  margin-top: 1rem;
  margin-bottom: 2.5rem !important;
  display: block; // Make it a block element
  margin-left: auto; // Center it if needed
  margin-right: auto;

  &:hover {
    background-color: var(--primary-color-judo) !important;
  }
}

.scoreInput {
  width: 50px;
  text-align: center;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.altjama {
  width: 80%; /* or any specific width */
  margin: 5rem 2rem 2rem 2rem;
  a {
    background-color: var(--primary-color-judo);
    color: white;

    padding:0.5rem;
    &:hover {
      background-color: #c61818;
      color: white;
    }
  }
}

// Add to leagueTeamEinzelBegeg.module.scss

.summaryRow {
  background-color: #f0f0f0;
  font-weight: bold;
}

.summaryTitle {
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
}

.summaryEmpty {
  background-color: #f0f0f0;
}

.summarySieg, .summaryUbw {
  text-align: center;
  font-size: 1.1em;

}
.summarySieg{
  background-color: var(--primary-color);
  color:white;
}
.summaryUbw {
  background-color: #8dadc7;
  color:white;
}


.decisionMatchRow {
  background-color: #ffe0e0;
}

.decisionMatchCell {
  padding: 5px;
  vertical-align: middle;
}

.decisionMatchLabel {
  display: flex;
  align-items: center;
  cursor: pointer;

  input[type="checkbox"] {
    margin-right: 8px;
    transform: scale(1.2);
  }
}

.clearSelectionButton {
  margin-left: 10px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 0.8em;
  cursor: pointer;

  &:hover {
    background-color: #e8e8e8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.resultIndicator {
  text-align: center;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 4px;
  display: inline-block;
  margin: 0 auto;
}

.winnerIndicator {
  background-color: #5aff81;
  color: black;
  padding: 0.5rem 2rem !important;
}

.loserIndicator {
  background-color: silver;
  color: #444;
  padding: 0.5rem 2rem !important;
}

.tieIndicator {
  background-color: #c1c1c1;
  color: #000;
  border: 1px solid silver;
}

.finalSummaryRow {
  font-size: 1.1em;
  background-color: #e8e8e8;

  .resultIndicator {
    text-transform: uppercase;
    font-weight: 900;
    padding: 4px 10px;
  }
}

.disabledControl {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #f0f0f0;
}

.valueChanged {
  background-color: #cce1fe; // Light blue background
  border: 1px solid #4a90e2; // Blue border
  box-shadow: 0 0 3px rgba(74, 144, 226, 0.3); // Subtle blue glow
  font-weight: 500; // Slightly bolder text

  // When hovered
  &:hover {
    background-color: #e5f1ff;
    border-color: #3a80d2;
  }

  // When focused
  &:focus {
    background-color: #cce6ff;
    border-color: #3a80d2;
    box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
    outline: none;
  }

  // Make sure it works well with other states
  &.siegWin {
    background-color: #b1fbc1; // Lighter green background
    border-color: #27d751; // Keep the green border
    color: black;
  }

  &.siegLoss {
    background-color: #fff0f0; // Lighter red background
    border-color: #e8818a; // Keep the red border
    color: black;
  }

  &.disabledControl {
    opacity: 0.7; // Slightly higher opacity than regular disabled controls
    cursor: not-allowed;
  }
}

// A more subtle alternative if the above is too prominent
.valueChangedSubtle {
  border-left: 3px solid #4a90e2; // Left border indicator
  padding-left: 4px; // Adjust padding to accommodate border

  &:focus {
    border-left-color: #3a80d2;
    outline: none;
  }
}

// For fighter dropdowns which are typically larger
.fighterValueChanged {
  @extend .valueChanged;
  border-left-width: 4px; // Slightly wider indicator
  //font-style: italic; // Additional visual differentiation
}

// Animated indicator that subtly pulses on first change
.valueChangedAnimated {
  @extend .valueChanged;
  animation: pulse 1.5s ease-in-out 1;

  @keyframes pulse {
    0% { box-shadow: 0 0 0 0 rgba(74, 144, 226, 0.7); }
    50% { box-shadow: 0 0 0 5px rgba(74, 144, 226, 0.4); }
    100% { box-shadow: 0 0 0 0 rgba(74, 144, 226, 0); }
  }
}

.fighterDropdown {
  &:disabled, &.disabledControl {
   // background-color: #e6f0ff; // Light blue background
    border-color: #4a90e2; // Blue border
    color: #0f48bf; // Blue text
    opacity: 0.7; // Slightly higher opacity than regular disabled controls
  }
}