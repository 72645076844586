.addAccount{
  font-weight: bold ;
  //color: blueviolet !important;
}

.level1 {
  background-color: #ffbaba;
  color:black;
  font-weight: bold;
}
.level2 {
  background-color: #dfedff;
  color:black
}
.level3 {
  background-color: #ffffff;
  color:black
}
.trlevel1 {
  background-color: #12a2ff;
  color:white
}
.trlevel2 {
  background-color: #8dd1ff;
  color:black
}
.trlevel3 {
  background-color: #d6ebff;
  color:black
}

.account{
  background-color: #ffffff;
  color:black;
}
.addaccount{
  background-color: #4bb03c;
  color:white;
}
.primary {
  background-color: #12a2ff;
  color:white;
}

.itemsWrapper {
  margin-bottom: 10rem;
}