.rolesContainer {
    padding: 1rem;
    max-width: 100%;
}

.searchBar {
    margin-bottom: 1rem;

    .searchInput {
        width: 100%;
        max-width: 300px;
        padding: 0.5rem;
    }
}

.groupSection {
    margin-bottom: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.groupHeader {
    padding: 1rem;
    background-color: var(--primary-color);
    color:white;
    border-radius: 8px 8px 0 0;
    margin: 0;
}

.headerRow {
    display: flex;
    padding: 0.3rem 1rem;
    background-color: var(--primary-background);
    border-bottom: 2px solid #999;

    @media (max-width: 768px) {
        //  display: none; // Hide headers on mobile
    }
}

.header {
    flex: 1;
    cursor: pointer;
    user-select: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover {
        color: #0066cc;
    }
}

.sortIcon {
    font-size: 0.875rem;
    color: #666;
}

.roleCard {
    border-bottom: 1px solid #bbb;
    padding: 0.5rem 1rem;

    &:last-child {
        border-bottom: none;
    }

    @media (min-width: 769px) {
        .roleCardContent {
            display: flex;
            gap: 1rem;

            .roleField {
                flex: 1;
                .label {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .roleCardContent {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .roleField {
                display: flex;
                justify-content: space-between;
                padding: 0.25rem 0;

                .label {
                    font-weight: bold;
                    color: #666;
                }
            }
        }
    }
}

.error {
    color: #ff0000;
    padding: 1rem;
    background-color: #fff0f0;
    border: 1px solid #ffcccc;
    border-radius: 4px;
    margin: 1rem;
}
